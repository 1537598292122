import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from '@rebass/grid';

import Callout from 'components/callout';
import Layout from 'components/layout';
import HeroSection from 'components/hero';
import Header from 'components/header';
import LeadSubmitForm from 'components/lead-submit-form';
import ScheduleBlock from 'components/mindbody-online';
import PageMeta from 'components/page-meta/page-meta';

import { ContentBox } from 'styles/boxes.css';
import { H1, H2, FocusH1 } from 'styles/headers.css';
import { BoldP } from 'styles/text.css';

export class FriendCampaignTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.setSubmitTrue = this.setSubmitTrue.bind(this);
  }

  setSubmitTrue() {
    this.setState({ submitted: true });
  }

  render() {
    const { data } = this.props;
    const { friend, campaign } = data.gwxapi;
    const pageTitle = `Become Unstoppable With ${friend.name}`;
    const apiUrl = data.site.siteMetadata.apiUrl;
    const boxingImage = data.boxingImage.childImageSharp.fluid;
    const pageDescription = `Join ${friend.name} at the first NYC Gloveworx studio in the Oculus at Westfield World Trade Center.`;

    return (
      <Layout>
        <PageMeta title={pageTitle} description={pageDescription} />

        <HeroSection>
          <Header />
          <Box width={0.9} m="auto">
            <H1>Gloveworx NYC</H1>
            <H1>Is Now Open</H1>
            <FocusH1>Book Your Session</FocusH1>
          </Box>
          <Callout text="Gloveworx World Trade Center" width={1} />
        </HeroSection>
        <ContentBox width={0.8} m="auto" p={[3, 4, 5, 5]}>
          {!this.state.submitted ? (
            <BoldP>
              {friend.name} has given you access to book your first boxing
              session at special opening month discounts. But space is limited.
              Be one of the first in NYC to claim your spot now.
            </BoldP>
          ) : (
            <H2>Great! Now confirm your spot in a session.</H2>
          )}
          {!this.state.submitted && (
            <LeadSubmitForm
              apiUrl={apiUrl}
              campaignId={campaign.id}
              sourceId={friend.id}
              sourceType="friend"
              setSubmitTrue={this.setSubmitTrue}
            />
          )}
          {campaign.mindbodyOnlineWidgetId && this.state.submitted && (
            <ScheduleBlock
              name="group"
              header="Session Schedule"
              mboID={campaign.mindbodyOnlineWidgetId}
              widgetType={campaign.mboWidgetType}
            />
          )}
        </ContentBox>
        <Box width={0.8} m="auto">
          <Img fluid={boxingImage} />
        </Box>
        <Callout text="YOUR WORKOUTS WILL NEVER BE THE SAME AGAIN." />
      </Layout>
    );
  }
}

FriendCampaignTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        apiUrl: PropTypes.string,
      }),
    }),
    boxingImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    gwxapi: PropTypes.shape({
      friend: PropTypes.object,
      campaign: PropTypes.object,
    }),
  }),
};

export default FriendCampaignTemplate;

export const friendQuery = graphql`
  query($slug: String!, $campaignId: String!) {
    site {
      siteMetadata {
        apiUrl
      }
    }
    boxingImage: file(name: { eq: "leyon-box.original" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gwxapi {
      friend(slug: $slug) {
        id
        name
        slug
        leads {
          id
          email
        }
      }
      campaign(id: $campaignId) {
        id
        mindbodyOnlineWidgetId
        mboWidgetType
        sessionFocus
        spotsLeft
      }
    }
  }
`;
